import React, { useState } from 'react';
import './VexoftPlaceholder.css'
import { ReactComponent as Logo } from './logo.svg';

const VexoftPlaceholder = () => {
    const [query, setQuery] = useState('');

    const handleSearch = () => {
        if (query) {
            alert(`You typed: '${query}'. Congrats! :)`);
        }
    };

    return (
        <div className="container">
            <Logo style={{marginBottom: "15px"}} />
            <div className="search-container">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search..."
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                />
                <button className="search-btn" onClick={handleSearch}>Search</button>
            </div>
        </div>
    );
};

export default VexoftPlaceholder;
