import './App.css';
import VexoftPlaceholder from './components/VexoftPlaceholder';

function App() {
  return (
    <VexoftPlaceholder />
  );
}

export default App;
